import PrivatePolicy from "./utils/PrivatePolicy";

declare var google;

class Site {
	public static PrivatePolicy = PrivatePolicy;

	public static discover($parent?: JQuery): void {
		PrivatePolicy.discover($parent);
	}

	public static init(): void {
		(<any> window).Site = Site;

		(<any> window).lang = $('html').prop('lang');

		PrivatePolicy.init();
	}
}

(function($) {
	function scrollTo(top, force?) {
		if (force) {
			$('html,body').scrollTop(top);
		} else {
			$('html,body').animate({
				scrollTop: top
			}, 900);
		}
	}

	function updateFloat() {
		var $header=$('.header');

		var top=$(window).scrollTop();
		var wh=$(window).height();

		if (top>0) {
			$('body').addClass('float');
			$('body').removeClass('nofloat');
		} else {
			$('body').removeClass('float');
			$('body').addClass('nofloat');
		}

		var hcy=$header.height();

		$('body').css('padding-top', hcy);

		// var $slideshow = $('.mainslider');
		// $slideshow.height(wh - hcy);
	}

	(<any> window).updateElements = function($parent) {
		(<any> $('.lightbox, .fancybox, a[rel="lightbox"]', $parent)).fancybox({
			titlePosition: 'over'
		});

		(<any> $('.svg-inject', $parent)).svgInject();

		$('.js-gotop', $parent).click(function(e) {
			e.preventDefault();

			scrollTo(0);
		});

		$('.js-godown', $parent).click(function(e) {
			e.preventDefault();

			var $this = $(this);
			var $header = $('.header');

			if ($('body').scrollTop() == 0) {
				$header.addClass('header--noanim');

				scrollTo(1, true);
				updateFloat();

				$header.removeClass('header--noanim');
			}

			window.setTimeout(function () {
				var name = $this.data('name');;
				var $dst = $('[name="' + name + '"]');

				var hh = $header.height();;
				var top = $dst.offset().top;

				scrollTo(top - hh);
			}, 1);
		});

		$('.cmstext h2', $parent).wrapInner('<span>');
		$('.cmstext h1', $parent).wrapInner('<span>');

		Site.discover($parent);
	}

	$(document).ready(function() {
		function initPreloader() {
		}

		function initMap() {
			var $map = $('.footer__map');

			if (!$map.length) {
				return;
			}

			var latlng = new google.maps.LatLng($map.data('lat'), $map.data('lng'));

			var mapType = new google.maps.StyledMapType([{
				featureType: "all",
				elementType: "all",
				stylers: [{
					saturation: -100
				}]
			}], { name:"grayscale" });

			var map = new google.maps.Map($map.get(0), {
				center: latlng,
				scrollwheel: false,
				zoom: 12,
				mapTypeId: 'grayscale'
			});

			map.mapTypes.set('grayscale', mapType);

			var ico = {
				url: '/theme/images/map-marker.png',
				anchor: new google.maps.Point(126, 184)
			};

			var marker = new google.maps.Marker({
				position: latlng,
				icon: ico,
				map: map
			});

			marker.addListener('click', function () {
				let url = $map.data('url');

				window.open(url, '_blank');
			});
		}

		function initHtml() {
			var $mainmenu = $('.mainmenu');

			$('.menubutton', $mainmenu).click(function(e) {
				e.preventDefault();

				if ($mainmenu.hasClass('mainmenu--opened')) {
					$mainmenu.removeClass('mainmenu--opened');
				} else {
					$mainmenu.addClass('mainmenu--opened');
				}
			});

			(<any> window).updateElements();

			$mainmenu.addClass('mainmenu--inited');

			$('.siteselect__text').each(function () {
				var $this = $(this);

				var text = $this.text();

				text =  '<span class="siteselect__subtext1">' + text.replace(' ', '</span> <span class="siteselect__subtext2">') + '</span>';

				$this.html(text);
			});
		}

		function initRwd() {
			var $header = $('.header');
			$header.addClass('header--noanim');

			$(window).resize(updateFloat);
			$(window).scroll(updateFloat);

			updateFloat();

			$header.removeClass('header--noanim');
		}

		function initStart() {
			var $videos = $('.videobg__item');

			$('.start__box').hover(function () {
				var $this = $(this);
				var name = $this.data('name');

				var $sel = $videos.filter('.videobg__item--' + name);

				$videos.parent().addClass('videobg--hovered');

				$videos.removeClass('videobg__item--selected');
				$sel.addClass('videobg__item--selected');

				$videos.not($sel).each(function () {
					var $video = $(this);
					var video = <HTMLVideoElement> $video[0];

					if (!$video.hasClass('videobg__item--selected')) {
						video.pause();
					}
				});

				if ($sel.hasClass('videobg__item--selected')) {
					(<HTMLVideoElement> $sel[0]).play();
				}
			});
		}

		initPreloader();
		initHtml();
		initMap();
		initRwd();
		initStart();
	});

	$(window).load(function () {
		$('body').addClass('body--loaded');
	});
})(jQuery);

Site.init();
